import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import appStyle from "../styles/App.module.css";
import { faSearch } from '@fortawesome/free-solid-svg-icons';


function Search() {
    return (
        <div className={appStyle['search-container']}>
            <div className={appStyle['filler'] }/>
            <input type="text" placeholder="Search" className={appStyle['rounded-input']} />
            <FontAwesomeIcon className={appStyle['search-icon']} icon={faSearch} size="lg" />
        </div>
    )
}

export default Search
