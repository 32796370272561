import React from 'react';
import CustomButton from './CustomButton';
import GoogleIcon from '../assets/icons/google-icon.svg'

const GoogleButton = ({ onClick, text,disabled }) => {
    return (
        <CustomButton className="custom-button-white" onClick={onClick} label={text} customIcon={GoogleIcon }/>
    )
};

GoogleButton.defaultProps = {
    onClick: () => { },
    text:""
}

export default GoogleButton;
