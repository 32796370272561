import {
    FETCH_CERTIFICATES_REQUEST,
    FETCH_CERTIFICATES_SUCCESS, FETCH_CERTIFICATES_ERROR,
} from "../../constants/actionTypes";
import { BASE_API_URL } from "../../constants/constants";
import { getUserToken } from "../../services/localStorage";

export const getUserCertificates = async (dispatch) => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    try {
        dispatch({ type: FETCH_CERTIFICATES_REQUEST });
        const response = await fetch(`${BASE_API_URL}/user_certificates`, headers);
        if (!response.ok) throw new Error(response.status);
        const certificates = await response.json();
        dispatch({ type: FETCH_CERTIFICATES_SUCCESS, certificates: {data: certificates, loading: false, error: null} || []});

    } catch (error) {
        dispatch({ type: FETCH_CERTIFICATES_ERROR,  certificates: {data: [], loading: false, error} });
        console.log("Something went wrong on retrieveCertificates:", error);
    }
}

export const downloadCertificates = async (course_id) => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    return fetch(`${BASE_API_URL}/users/course/${course_id}/certificate`, headers);
}
