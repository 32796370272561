import {
    LOGIN_WITH_EMAIL_ERROR,
    LOGIN_WITH_EMAIL_REQUEST,
    LOGIN_WITH_EMAIL_SUCCESS,
    SIGNUP_WITH_EMAIL_REQUEST,
    SIGNUP_WITH_EMAIL_SUCCESS,
    SIGNUP_WITH_EMAIL_ERROR,
    LOGIN_WITH_GOOGLE_ERROR,
    LOGIN_WITH_GOOGLE_REQUEST,
    LOGIN_WITH_GOOGLE_SUCCESS,
    RESET_PASSWORD_SUCCESS_EMAIL,
    RESET_PASSWORD_UPDATE_REQUEST,
    RESET_PASSWORD_UPDATE_SUCCESS_EMAIL,
    RESET_PASSWORD_UPDATE_ERROR_EMAIL,
    LOGOUT,
} from "../../constants/actionTypes";
import { BASE_API_URL } from "../../constants/constants";

let userModel = {
    id:"",
    email: "",
    first_name: "",
    last_name: "",
    token: "",
    username: "",
    image: "",
};

export const loginWithEmail = async (dispatch, payload) => {
    const headers = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload),
    };

    try {
        dispatch({ type: LOGIN_WITH_EMAIL_REQUEST });
        const response = await fetch(`${BASE_API_URL}/users/login`, headers);
        const data = await response.json();
        if (data?.user) {
            userModel.id = data.user.id || "";
            userModel.email = data.user.email;
            userModel.first_name = data.user.first_name;
            userModel.last_name = data.user.last_name;
            userModel.token = data.user.token;
            userModel.username = data.user.username;
            userModel.image = data.user?.image;
            userModel.require_new_password = data.user?.require_new_password;

            dispatch({ type: LOGIN_WITH_EMAIL_SUCCESS, payload: userModel });
            //Save user token in local storage

            localStorage.setItem("user", JSON.stringify(userModel));
            return data;
        }
        dispatch({ type: LOGIN_WITH_EMAIL_ERROR, error: data?.errors });
    } catch (error) {
        console.log("Something went wrong - loginWithEmail error:", error);
    }
};
export const signupWithEmail = async (dispatch, payload) => {
    const headers = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload),
    };

    try {
        dispatch({ type: SIGNUP_WITH_EMAIL_REQUEST });
        const response = await fetch(`${BASE_API_URL}/users/signup`, headers);
        const data = await response.json();

        if (data?.user?.id) {
            userModel.id = data.user.id || "";
            userModel.email = data.user.email;
            userModel.token = data.user.token;
            userModel.username = data.user.username;
            userModel.image = data.user?.image;
            dispatch({ type: SIGNUP_WITH_EMAIL_SUCCESS, payload: data.user });
            localStorage.setItem("user", JSON.stringify(userModel));
            return data.user;
        } else {
            dispatch({ type: SIGNUP_WITH_EMAIL_ERROR, error: data?.errors });
        }

    } catch (error) {
        console.log("Something went wrong - signupWithEmail error:", error);
        dispatch({ type: SIGNUP_WITH_EMAIL_ERROR, error:"Something went wrong, try again, please!" });

    }
};

export const loginWithGoogle = async (dispatch, data) => {
    try {
        dispatch({ type: LOGIN_WITH_GOOGLE_REQUEST });
        if (data) {
            userModel.id = data?.id || "";
            userModel.email = data?.email;
            userModel.token = data?.token;
            userModel.username = data?.username;
            userModel.first_name = data?.first_name;
            userModel.last_name = data?.last_name;
            userModel.image = data.user?.image;

            localStorage.setItem("user", JSON.stringify(userModel));
            dispatch({ type: LOGIN_WITH_GOOGLE_SUCCESS, payload: userModel });
        }
    } catch (error) {
        dispatch({ type: LOGIN_WITH_GOOGLE_ERROR, error });
    }
};

export const logout = async (dispatch) => {
    await localStorage.removeItem("user");
    dispatch({ type: LOGOUT });
};

export const requestAuthenticityToken = async () => {
    const headers = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    try {
        const response = await fetch(`${BASE_API_URL}/users/login`, headers);
        const data = await response.json();
        if (!data?.authenticity_token) return;
        return data;
    } catch (error) {
        console.log("Errors on getAuthenticityToken", error);
    }
};

export const resetEmail = async (dispatch, payload) => {
    const headers = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload)
    };

    try {
        const response = await fetch(`${BASE_API_URL}/users/password`, headers);
        const data = await response.json();
        if (data) {
            dispatch({ type: RESET_PASSWORD_SUCCESS_EMAIL });
            return data;
        }
        dispatch({ type: LOGIN_WITH_EMAIL_ERROR, error: data?.errors });
    } catch (error) {
        console.log("Something went wrong - resetEmail error:", error);
    }
};

export const updatePassword = async (dispatch, payload) => {
    const headers = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload)
    };
    dispatch({ type: RESET_PASSWORD_UPDATE_REQUEST });
    try {
        const response = await fetch(`${BASE_API_URL}/users/password`, headers);
        const data = await response.status;
        if (data >= 200 && data <400) {
            dispatch({ type: RESET_PASSWORD_UPDATE_SUCCESS_EMAIL });
        }
        dispatch({ type: RESET_PASSWORD_UPDATE_ERROR_EMAIL, error: data?.errors });
    } catch (error) {
        console.log("Something went wrong - updatePassword error:", error);
    }
};
