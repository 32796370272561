import React, {useState} from "react";
import {Nav, Navbar} from "react-bootstrap";
import {useDashboardContext} from "../context/dashboardContext";
import '../styles/Sidebar.css';
import CustomSpinner from "./CustomSpinner";
import Menu from "./Menu";

const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const {courseModules, isLoading} = useDashboardContext();
    return (
        <>
            {/*Desktop Nav*/}
            <Navbar expand="xl" className="col-md-12 d-none d-md-block sidebar p-0 mt-5 p-1"
            >
                {isLoading ? <CustomSpinner /> : <Menu items={courseModules} />}
            </Navbar>

            {/*Mobile Nav*/}
            <Navbar expand="lg"
                    className="d-md-none col-md-12 d-block sidebar-mobile justify-content-start d-flex custom-scrollbar"
            >
                <button onClick={(e)=>{e.preventDefault(); setIsCollapsed(!isCollapsed)}}
                        type="button"
                        aria-label="Toggle navigation"
                        className="navbar-toggler">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`bounce-navbar-nav ${isCollapsed ? 'collapsed' : 'show'}`}>
                    <div className={`btn-toggle-wrapper`}>
                        <button onClick={(e)=>{e.preventDefault(); setIsCollapsed(!isCollapsed)}}
                                type="button"
                                aria-label="Toggle navigation"
                                className="navbar-toggler open">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <Nav className={`${isCollapsed ? 'collapsed' : ''} me-auto bounce-wrapper`}>
                        {isLoading ? <CustomSpinner/> : <Menu items={courseModules}/>}
                    </Nav>
                </div>
            </Navbar>
        </>
    );
};
export default Sidebar;
