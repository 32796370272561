import React, {useState, useRef, useEffect} from "react";
import { Form } from "react-bootstrap";
import appStyle from "../styles/App.module.css";
import { Link, useHistory } from 'react-router-dom';
import {loginWithEmail, logout, useAuthContext} from "../context/authContext";
import { loginWithGoogle } from "../context/authContext/authActions";
import { MAIN_DOMAIN } from "../constants/constants";
import AlertDismissible from "./AlertDismissible";
import GoogleButton from "./GoogleButton";
import { requestGoogleForm } from "../services/google/google";
import useEventListener from "../hooks/useEventListener";
import Logo from "./Logo";
import CustomButton from "./CustomButton";
import CustomSpinner from "./CustomSpinner";

const SingInForm = () => {
  const { state: { isLoading, error }, dispatch } = useAuthContext();
  const newWindowRef = useRef(null);

  const onMessageReceiveFromBackend = React.useCallback(async (event) => {
    if (event.origin.startsWith(MAIN_DOMAIN)) {
      if (event.data.user) {
        await loginWithGoogle(dispatch, event.data.user);
        if (newWindowRef.current) {
          newWindowRef.current.window.close();
        }

      }

    } else {
      console.log('Unauthorized event!', event);
    }

  }, [dispatch]);

  useEventListener(onMessageReceiveFromBackend);
  const history = useHistory();
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [showAlert, setShowAlert] = useState(false);

  const _requestGoogleSigninForm = async () => {
    newWindowRef.current = requestGoogleForm();
  }

  const handleLoginWithEmail = async (e) => {
    e.preventDefault();
    const { email, password } = inputs;
    const payload = { user: { email, password } };
    const data = await loginWithEmail(dispatch, payload);
    if (!data) {
      setShowAlert(true);
    }
    if (!data?.user) return;
    history.push('/dashboard');
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
  }

  const onCloseAlert = () => {
    setShowAlert(!showAlert);
  }
  const ErrorMessage = () => {
    const errors = Object.keys(error)[0] || "";
    //TODO waiting for the exact shape of the errors object
    return (
      <AlertDismissible onCloseAlert={onCloseAlert} showAlert={showAlert} content="Invalid credentials" />
    )
  }

  // remove old session
  useEffect(()=>{
    logout(dispatch);
  }, [])

  const LoadingIndicator = () => {
    return <div>Loading</div>
  }

  const LoginWithGoogleButton = () => {
    return (
      <GoogleButton  onClick={_requestGoogleSigninForm} text="Sign in with Google" />
    )
  }

  return (
    <>
      <div className={appStyle['form-wrapper']}>
        <div className={appStyle['form-header-container']}>
          <Logo />
          <div className={appStyle['form-header-title']}>Sign in to your account</div>
        </div>
        <LoginWithGoogleButton />
        <p className={`mb-0 ` + appStyle.text}>Or continue with email</p>
        {error && <ErrorMessage />}
        <Form onSubmit={handleLoginWithEmail} >
          <div className={appStyle['b-form-body']}>
            <Form.Group controlId="formBasicEmail" className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>Email address</div>
              <Form.Control type="email" required name="email" pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"} value={inputs["email"]} onChange={onChangeHandler} placeholder="Email" />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className={appStyle['custom-form-group']}>
            <div className={appStyle['form-custom-label']}>Password</div>
              <Form.Control type="password" name="password" required value={inputs["password"]} onChange={onChangeHandler} placeholder="Password" />
            </Form.Group>

          </div>
          <div className={appStyle["remember-me-wrapper"]}>
            <div className={appStyle['remember-me-check-container']}>
              <Form.Check id={`remember`} type="checkbox" className={appStyle['custom-checkbox']}/>
              <label htmlFor={`remember`} className={`m-0 pt-1 ` + appStyle["terms-and-conditions"]}>
                Remember me
              </label>
            </div>
            <Link to="/reset-password" className={appStyle['custom-link']} >
              Forgot your password?
            </Link>

          </div>
          {isLoading && <div className={`my-2`}><CustomSpinner /></div>}
          <CustomButton
            disabled={!inputs.email || !inputs.password}
            label="Sign in"
            className={`custom-button-default ${!inputs.email || !inputs.password ? 'disabled' :'' }`}
            type="submit"
          />



          <div className={appStyle["form-footer"]}>
            <p>
            If you don’t have an account {" "}
              <span>
                <Link to="/signup" className={appStyle['custom-link-blue']} >Sign up</Link>
              </span>
            </p>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SingInForm;
