import * as React from "react";
import {
    FETCH_MODULES_REQUEST,
    FETCH_MODULES_SUCCESS,
    FETCH_MODULES_ERROR,
    FETCH_COURSES_REQUEST,
    FETCH_COURSES_SUCCESS,
    FETCH_COURSES_ERROR,
    SET_CURRENT_MODULE,
    SET_CURRENT_SECTION,
    FETCH_KNOWLEDGE_CHECK_REQUEST,
    FETCH_KNOWLEDGE_CHECK_ERROR,
    POST_KNOWLEDGE_CHECK_REQUEST,
    FETCH_KNOWLEDGE_CHECK_SUCCESS,
    POST_KNOWLEDGE_CHECK_SUCCESS,
    POST_KNOWLEDGE_CHECK_MODE,
    SET_SECTION_PROGRESS_REQUEST,
    SET_SECTION_PROGRESS_SUCCESS,
    SET_VIDEO_RATE_SUCCESS,
    SET_VIDEO_PROGRESS_SUCCESS,
    SET_VIDEO_PROGRESS_REQUEST,
    SET_CURRENT_VIDEO,
    SET_CURRENT_SCROLL,
    FETCH_CERTIFICATES_REQUEST, FETCH_CERTIFICATES_SUCCESS, FETCH_CERTIFICATES_ERROR, SET_GLOBAL_LOADING

} from "../../constants/actionTypes";
import { fetchCourses } from "./courseActions";
import { fetchKnowledgeCheck } from "./knowledgeCheckActions";

//Context
const DashboardContext = React.createContext();


//Reducer
const initialState = {
    courses: [],
    certificates: {
        data: [],
        loading: false,
        error: null
    },
    globalLoading: false,
    modules: [],
    knowledgeChecks: [],
    courseModules: [],
    isLoading: false,
    isFetchingKnowledgeChecks: false,
    isSettingSectionProgress: false,
    errors: null,
    currentVideo: null,
    currentScroll: null,
    currentModule: null,
    currentSection: null,
    currentGradeKCA: {},
    knowledgeCheckMode: false,
    totalCorrect: 0
};
const dashboardReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_MODULES_REQUEST:
        case FETCH_COURSES_REQUEST:
        case POST_KNOWLEDGE_CHECK_REQUEST:
            return {
                ...state,
                isLoading: true,
                errors: null,
            };
        case FETCH_KNOWLEDGE_CHECK_REQUEST:
            return {
                ...state,
                errors: null,
                isFetchingKnowledgeChecks: true,
            };
        case FETCH_KNOWLEDGE_CHECK_ERROR:
            return {
                ...state,
                isFetchingKnowledgeChecks: false,
                errors: action.errors
            }
        case FETCH_MODULES_SUCCESS:
            return {
                ...state,
                modules: action.modules,
                isLoading: false,
                errors: null,
            }
        case FETCH_MODULES_ERROR:
        case FETCH_COURSES_ERROR:
            return {
                ...state,
                isLoading: false,
                errors: action.errors
            }
        case FETCH_COURSES_SUCCESS:
            return {
                ...state,
                modules: action.modules,
                courses: action.courses,
                courseModules: action.courseModules,
                isLoading: false,
                errors: null,
            }
        case FETCH_KNOWLEDGE_CHECK_SUCCESS:
            return {
                ...state,
                knowledgeChecks: action.knowledgeCheck,
                isFetchingKnowledgeChecks: false,
                errors: null,
            }
        case SET_CURRENT_MODULE:
            return {
                ...state,
                currentModule: action.module && state.modules.find(e => e.id === action.module.id),
            }
        case SET_CURRENT_SECTION:
            return {
                ...state,
                currentSection: action.section,
            }
        case POST_KNOWLEDGE_CHECK_SUCCESS:
            const numberOfQuestions = action.currentGradeKCA.length
            const correct = action.currentGradeKCA.map((item => item.correct === true))
            const totalCorrect = correct.filter((item => item === true)).length
            return {
                ...state,
                currentGradeKCA: action.currentGradeKCA,
                totalCorrect: (totalCorrect / numberOfQuestions) * 100,
                isLoading: false,
                errors: null,
            }
        case POST_KNOWLEDGE_CHECK_MODE:
            return {
                ...state,
                knowledgeCheckMode: action.knowledgeCheckMode
            }
        case SET_SECTION_PROGRESS_REQUEST:
            return {
                ...state,
                isSettingSectionProgress: true,
            }
        case SET_SECTION_PROGRESS_SUCCESS:
            return {
                ...state,
                isSettingSectionProgress: false,
                currentModule: {
                    ...state.currentModule,
                    items: state.currentModule && state.currentModule?.items.map((section, i) => section?.id === action.section.id
                        ? {
                            ...section,
                            progress: action.section.progress
                        } : section)

                },
            }
        case SET_VIDEO_PROGRESS_REQUEST:
            return {
                ...state,
                isSettingSectionProgress: true,
            }
        case SET_VIDEO_RATE_SUCCESS :
            const activeModule = {
                ...action.currentModule,
                items: action.currentModule && action.currentModule.items.map(section => {
                    return {
                        ...section,
                        items: section.items.map(video => {
                            if (video.id === action.currentVideo.id){

                                video = {
                                    ...video,
                                    user_rating: [{
                                        ...(video.user_rating.length && video.user_rating[0]),
                                        rating: action.currentVideo.user_rating[0]?.rating || 0
                                    }]
                                };
                            }

                            return video;
                        })
                    }
                })
            };

            const moduleIndex = state.modules.findIndex(mod => mod.id === activeModule.id);
            let modulesTemp = [...state.modules];

            modulesTemp[moduleIndex] = {...activeModule};

            return {
                ...state,
                isSettingSectionProgress: false,
                modules: [...modulesTemp],
            };
        case SET_VIDEO_PROGRESS_SUCCESS:
            let currentModule = {
                ...state.currentModule,
                items: [...state.currentModule && state.currentModule.items.map(section => {
                    return {
                        ...section,
                        items: [...section.items.map(video => {
                            // Video match progress?
                            if (video.id === action.currentVideo.module_section_video_id){
                                video = {
                                    ...video,
                                    progresses: [{
                                        ...(video.progresses.length && video.progresses[0] || []),
                                        ...action.currentVideo
                                    }]
                                };
                            }

                            return video;
                        })]
                    }
                })]
            };

            let index = state.modules.findIndex(e => e.id === currentModule.id);
            let temp = [...state.modules];

            temp[index] = {...currentModule};

            return {
                ...state,
                isSettingSectionProgress: false,
                modules: [...temp],
                currentModule: {...temp[index]},
            };
        case SET_CURRENT_VIDEO:
            return {
                ...state,
                currentVideo: action.video,
            };
        case SET_CURRENT_SCROLL:
            return {
                ...state,
                currentScroll: action.video,
            };
        case FETCH_CERTIFICATES_REQUEST:
            return {
                ...state,
                certificates: {data: [], loading: true, error: null},
            };
        case FETCH_CERTIFICATES_SUCCESS:
            return {
                ...state,
                certificates: {data: action.certificates.data, loading: false, error: null},
            };
        case FETCH_CERTIFICATES_ERROR:
            return {
                ...state,
                certificates: {data: [], loading: false, error: action.certificates.error},
            };
        case SET_GLOBAL_LOADING:
            return {
                ...state,
                globalLoading: action.loading
            }
        default:
            throw new Error(`Unknown action type ${action.type}`)
    }
};

//Provider
export const DashboardProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(dashboardReducer, initialState);
    const value = { ...state, dispatch };
    React.useEffect(() => {

        if (!state.courses.length) {
            fetchCourses(dispatch);
        }
        if (!state.knowledgeChecks?.length && state.currentSection) {
            fetchKnowledgeCheck(dispatch, state.currentSection?.id);
        }
    }, []);

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

//hook
export const useDashboardContext = () => {
    const context = React.useContext(DashboardContext);
    if (context === undefined) {
        throw new Error("useDashboardContext must be within a DashboardProvider");
    }
    return context;
};
