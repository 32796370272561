import React, {useEffect, useRef, useState} from "react";
import { Button, Form } from "react-bootstrap";

import appStyle from "../styles/App.module.css";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "./CustomModal";
import GoogleButton from "./GoogleButton";
import { requestGoogleForm } from "../services/google/google";
import { MAIN_DOMAIN } from "../constants/constants";
import { loginWithGoogle, signupWithEmail } from "../context/authContext/authActions";
import { useAuthContext } from "../context/authContext";
import useEventListener from "../hooks/useEventListener";
import AlertDismissible from "./AlertDismissible";
import Logo from "./Logo";
import CustomButton from "./CustomButton";
import TermsAndConditions from "./TermsAndConditions";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import AlertBounceDismissible from "./AlertBounceDismissible";
import CustomSpinner from "./CustomSpinner";
const SignUpForm = () => {
  const { dispatch, state: { error } } = useAuthContext();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    tos_accepted: true
  });
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [showAlert, setShowAlert] = useState(false)


  const _onAcceptTC = (isTermsAccepted) => {
    if (isTermsAccepted) {
      setIsTCAccepted(true);
    } else {
      setIsTCAccepted(false)
    }
  }

  const toggle = () => setModal(!modal);

  const newWindowRef = useRef(null);

  const onMessageReceiveFromBackend = React.useCallback(async (event) => {
    if (event.origin.startsWith(MAIN_DOMAIN)) {
      if (event.data.user) {
        await loginWithGoogle(dispatch, event.data.user);
        if (newWindowRef.current) {
          newWindowRef.current.window.close();
        }

      }

    } else {
      // console.log('Unauthorized event!', event);
    }

  }, [dispatch]);

  useEffect(()=>{
    if(!showAlert){
      setShowAlert(true);
    }
  },[error])

  useEventListener(onMessageReceiveFromBackend);

  const _requestGoogleSignupForm = () => {
    newWindowRef.current = requestGoogleForm();
  }


  const SignUpWithGoogleButton = () => {
    return (
      <GoogleButton onClick={_requestGoogleSignupForm} disabled={!isTCAccepted} text="Sign up with Google" />
    )
  }
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
  }
  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { first_name, last_name, tos_accepted, email, password, registration_code } = inputs;
    const username = email;
    const payload = { user: { first_name, last_name, username, tos_accepted, email, password,registration_code } };
    const data = await signupWithEmail(dispatch, payload);
    setIsLoading(false);

    if (!data?.user) return;
    history.push('/dashboard');
  }
  const onCloseAlert = () => {
    setShowAlert(prevShowAlert => !prevShowAlert);
  }
  const Errors = () => {
    let output = "";
    if (typeof error === "object") {
      for (const key in error) {
        if (Object.hasOwnProperty.call(error, key)) {
          const sError = error[key];
          output += `${sError}\n`;
        }
      }
    } else {
      output = error || 'Unexpected error';
    }

    return (<AlertBounceDismissible cls={`alert-bounce alert-bounce-warning`} onCloseAlert={onCloseAlert} showAlert={showAlert} content={output} icon={faExclamationTriangle} />)

  }

  const isFormValid = !inputs.email || !inputs.password || !inputs.confirmPassword || !inputs.first_name || !inputs.last_name || !isTCAccepted;
  return (
    <>
      <div className={appStyle["form-wrapper"]}>
        <div className={appStyle['form-header-container']}>
          <Logo />
          <div className={appStyle['form-header-title']}>Let’s get started</div>
        </div>
        <SignUpWithGoogleButton />
        <p className={`mb-0 ` + appStyle.text}> Or continue with email</p>
        {error && <Errors />}

        <Form onSubmit={handleSignupWithEmail}>
          <div className={appStyle['b-form-body']}>
            <div className={appStyle['form-names-container']}>
              <Form.Group className={appStyle['custom-form-group']}>
                <div className={appStyle['form-custom-label']}>First Name</div>
                <Form.Control type="text" placeholder="First Name" value={inputs["first_name"]} onChange={onChangeHandler} required name="first_name" />
              </Form.Group>

              <Form.Group className={appStyle['custom-form-group']}>
                <div className={appStyle['form-custom-label']}>Last Name</div>
                <Form.Control type="text" placeholder="Last Name" required name="last_name" value={inputs["last_name"]} onChange={onChangeHandler} />
              </Form.Group>
            </div>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>Email Address</div>
              <Form.Control type="email" placeholder="Email" required name="email" value={inputs["email"]} onChange={onChangeHandler} pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"} />
            </Form.Group>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>Password</div>
              <Form.Control type="password" placeholder="Password" value={inputs["password"]} onChange={onChangeHandler} required name="password" />
            </Form.Group>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>Confirm Password</div>
              <Form.Control type="password" placeholder="Confirm Password" value={inputs["confirmPassword"]} onChange={onChangeHandler} required name="confirmPassword" />
            </Form.Group>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>Registration Code</div>
              <Form.Control type="text" placeholder="Your registration code" required value={inputs["registration_code"]} onChange={onChangeHandler} name="registration_code" />
            </Form.Group>
          </div>
          <div className={appStyle["terms-wrapper"]}>
            <Form.Check id={`terms`} type="checkbox" checked={isTCAccepted} onChange={() => setIsTCAccepted(isTermsAccepted => !isTermsAccepted)} />
            <label htmlFor={`terms`} className={`mb-0 ` + appStyle["terms-and-conditions"]}>
              I agree with the{" "}
              <span onClick={toggle}>
                <a href="#" className={appStyle['custom-link-blue']}>Terms & Conditions</a>
              </span>
            </label>
          </div>
          {/*Spinner*/}
          {isLoading && <div className={`my-2`}><CustomSpinner /></div>}
          <CustomModal
            isOpen={modal}
            handleClose={toggle}
            onAcceptTC={_onAcceptTC}
            modalTitle={"Terms & Conditions"}
            modalContent={<TermsAndConditions />}
            showFooter={true}
            fullscreen={true}
          />
          <CustomButton
            disabled={isFormValid}
            label="Sign up"
            className={`custom-button-default ${isFormValid ? 'disabled' : ''}`}
            type="submit"
          />
          <div className={`mt-4 ` + appStyle["form-footer"]}>
            <p>
              If you already have an account {" "}
              <span>
                <Link to="/signin" className={appStyle['custom-link-blue']}>Sign in</Link>
              </span>
            </p>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignUpForm;
