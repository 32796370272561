import React, {useEffect, useState} from 'react'
import {SET_CURRENT_MODULE, SET_CURRENT_SCROLL, SET_CURRENT_SECTION, SET_CURRENT_VIDEO} from '../constants/actionTypes';
import { useDashboardContext } from '../context/dashboardContext';
import ModuleHeader from './ModuleHeader';
import SectionHome from './SectionHome';
import SectionItem from './SectionItem';
import Accordion from 'react-bootstrap/Accordion';

const Module = ({ module, currentComponentSection }) => {
    const { dispatch, knowledgeCheckMode, currentVideo, currentScroll, currentSection, modules} = useDashboardContext();
    const [moduleContinue, setModuleContinue] = useState(true)

    const onSetcurrentComponentSection = (section) => {
        dispatch({ type: SET_CURRENT_SECTION, section })
    }
    let defaultActiveKey = null;
    const activeKey = 1
    const onClickContinueButton = (eventKey) => {
        setModuleContinue(!moduleContinue)
    }

    // open section when click on a video in menu
    useEffect(() => {
        if (currentVideo) {
            // Check if video belongs to current section
            const isVideoInCurrentSection = currentVideo.module_section_id === currentSection?.id;

            // If not, find and set the right section and module
            if(!isVideoInCurrentSection){
                // TODO: Improve the way to get parent relationship
                let i = 0;
                let _selectedModule = null;
                let _selectedSection = null;
                while(i < modules.length){
                    let j = 0;
                    // modules
                    while( j < modules[i].items.length){
                        // sections
                        if (modules[i].items[j].id === currentVideo.module_section_id){
                            _selectedModule = modules[i]
                            _selectedSection = modules[i].items[j]
                            break;
                        }
                        j++;
                    }
                    if(_selectedModule){
                        break;
                    }
                    i++;
                }
                dispatch({ type: SET_CURRENT_MODULE, module: _selectedModule });
                dispatch({ type: SET_CURRENT_SECTION, section: _selectedSection });
            }

            const sectionCollapsed = document.getElementById(`sec-${currentVideo.module_section_id}`);
            if(sectionCollapsed){
                const isNotCollapsed = sectionCollapsed.nextElementSibling.classList.contains('show');
                if(!isNotCollapsed){
                    document.getElementById(`sec-${currentVideo.module_section_id}`).click();
                    document.getElementById(`sec-${currentVideo.module_section_id}`).parentNode.click();
                    dispatch({type: SET_CURRENT_VIDEO, video: null});
                }
                dispatch({type: SET_CURRENT_SCROLL, video: currentVideo});
            }else{
                dispatch({type: SET_CURRENT_SCROLL, video: {...currentVideo, timeout: 1}});
            }
        }
    }, [currentVideo]);

    useEffect(() => {
        if(currentScroll){
            const element = document.getElementById(`video-${currentScroll.id}`);
            if (element) setTimeout(() => { element.scrollIntoView({behavior: 'smooth'}); }, currentScroll.timeout || 500);
            dispatch({type: SET_CURRENT_SCROLL, video: null});
        }
    }, [currentScroll])

    return module && module?.items?.length > 0 ? (<div>
        {moduleContinue && <ModuleHeader moduleName={module.name} description={module.description || 'Module description'} onClick={onClickContinueButton} />}
        <Accordion className={`${knowledgeCheckMode ? '' : 'section-container'}`} >
            {module.items.map((section, idx) => {
                let sectionBody = <>No data</>;
                if (section.items.length > 0) {
                    sectionBody = <SectionHome videos={section.items} />;
                }

                if (section?.id === currentComponentSection?.id) {
                    defaultActiveKey = idx;
                }
                return <SectionItem activeKey={activeKey} section={section} index={section?.id} onSetCurrentSection={onSetcurrentComponentSection} sectionScore={section?.progress || 0} key={`section-${section.name}-${idx * 10}`} name={section.name || section.title} children={sectionBody} eventKey={idx} defaultActiveKey={defaultActiveKey} />;
            })}
        </Accordion></div>) : null;

}

export default Module
