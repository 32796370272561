import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import appStyle from "../styles/App.module.css";
import CustomButton from "./CustomButton";

const CustomModal = (props) => {
  const {
    isOpen,
    handleClose,
    modalTitle,
    modalContent,
    showFooter,
    fullscreen,
    onAcceptTC
  } = props;

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const _onAcceptTC = () => {
    onAcceptTC(isTermsAccepted);
    handleClose()
  }
  const onDeclineTC = () => {
    onAcceptTC(false);
    setIsTermsAccepted(false);
    handleClose();
  }
  return (
    <Modal
      show={isOpen}
      fullscreen={fullscreen || false}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header className={appStyle['custom-modal-header']}>
        {modalTitle && <div className={appStyle['custom-modal-title']}>{modalTitle}</div>}
      </Modal.Header>
      {modalContent && <Modal.Body>{modalContent}</Modal.Body>}

      {showFooter && (
        <>
          <div className={appStyle['terms-and-conditions-footer']}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check className={`cursor-pointer separate ` + appStyle['tac-checkbox']} onChange={() => setIsTermsAccepted(!isTermsAccepted)} checked={isTermsAccepted} type="checkbox" label=" I have read and agree to Bounceback’s Terms & Conditions." />
            </Form.Group>
          </div>
          <div className={appStyle['terms-and-conditions-actions']}>
            <CustomButton className="custom-button-white" label="Decline" onClick={onDeclineTC} />

            <div />
            <CustomButton disabled={!isTermsAccepted} className={`b-custom-button-default ${!isTermsAccepted ? 'disabled' : ''}`} label="Accept" onClick={_onAcceptTC} />

          </div>
        </>
      )}
    </Modal>
  );
};
CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  showFooter: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

export default CustomModal;
