import React from 'react'
import Search from './Search'
import UserNotifications from './UserNotifications'
import UserSettings from './UserSettings'
import dashboardStyle from '../styles/Dashboard.module.css';
import {Col, Row} from "react-bootstrap";

const Header = ({user = ""}) => {
    return (
        <>
            <Row className={dashboardStyle['header-container']}>

                <Col xs={12} className={`d-flex d-sm-none justify-content-end mb-3`}>
                    <div className={`d-flex justify-content-end`}>
                        <span className={`d-none d-sm-flex` + dashboardStyle['username-settings']}>{user?.username || user?.first_name}</span>
                        <UserSettings className={`mr-3`}/>
                        {/*<UserNotifications/>*/}
                    </div>
                </Col>

                <Col sm={8} lg={4} className={dashboardStyle['title']}>
                    <h3>Welcome, <span className={`text-black-50 text-break`}> {user.username || user?.first_name}</span></h3>
                </Col>

                <Col sm={4}  className={`d-none d-sm-flex d-md-flex d-lg-none justify-content-end`}>
                    <div className={`d-flex justify-content-end`}>
                        <span className={`d-none d-sm-flex` + dashboardStyle['username-settings']}>{user?.username || user?.first_name}</span>
                        <UserSettings className={`mr-3`}/>
                        {/*<UserNotifications/>*/}
                    </div>
                </Col>

                <Col sm={12} xs={12} lg={5} className={`mt-4 mt-lg-0`}>
                    <Search/>
                </Col>

                <Col sm={3} lg={3} className={`d-none d-lg-flex align-items-center justify-content-end`}>
                    <span className={dashboardStyle['username-settings']}>{user?.username || user?.first_name}</span>
                    <UserSettings className={`mr-3`}/>
                    {/*<UserNotifications/>*/}
                </Col>
            </Row>
        </>

    )
}

export default Header
