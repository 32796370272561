import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import CustomButton from './CustomButton';

const CustomModalKnowledgeCheck = (props) => {
    const {
        isOpen,
        handleClose,
        modalTitle,
        modalContent,
        showFooter,
        score,
        fullscreen,
    } = props;

    return (
        <Modal
            show={isOpen}
            fullscreen={fullscreen || false}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <div>
                    {score < 75 && <div className="modal-container-success">
                        <label className="modal-success-text">
                            Try again, you need 75% to pass
                        </label>
                    </div>
                    }
                    {score > 75 &&
                        <div className="modal-container-success">
                            <label className="modal-success-text">
                                Score
                            </label>
                            <div className="modal-success-number-container ">
                                <label className="modal-success-number-text">
                                    {score}%
                                </label>
                            </div>
                        </div>
                    }

                    {score < 75 &&
                        <div className="modal-container-success">
                            <label className="modal-success-text">
                                Score
                            </label>
                            <div className="modal-fail-number-container ">
                                <label className="modal-fail-number-text">
                                    {score}%
                                </label>
                            </div>
                        </div>
                    }
                    {score > 75 && <div className="modal-container-success">
                        <label className="modal-success-text">
                            Congratulations
                        </label>
                    </div>
                    }
                </div>
            </Modal.Body>

            {showFooter && (
                <>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                        {score > 75 && <div className="custom-submit-container-modal">
                            <button className="custom-submit-button-modal">
                                <label className="custom-submit-button-text" onClick={() => handleClose()}>Keep going</label>
                            </button>
                        </div>
                        }
                        {score < 75 &&
                            <div>
                                <CustomButton className="custom-button-back" label="Back" onClick={() => handleClose()} />
                                <button className="custom-submit-button-retake " onClick={() => handleClose()}>
                                    <label className="custom-submit-button-text">Retake</label>
                                </button>
                            </div>
                        }
                    </Modal.Footer></>
            )}
        </Modal>
    );
};
CustomModalKnowledgeCheck.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string,
    modalContent: PropTypes.string,
    showFooter: PropTypes.bool,
    fullscreen: PropTypes.bool,
};

export default CustomModalKnowledgeCheck;
