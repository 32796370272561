import {
    FETCH_KNOWLEDGE_CHECK_REQUEST,
    FETCH_KNOWLEDGE_CHECK_SUCCESS, FETCH_KNOWLEDGE_CHECK_ERROR,
    POST_KNOWLEDGE_CHECK_SUCCESS,
    POST_KNOWLEDGE_CHECK_REQUEST,
    POST_KNOWLEDGE_CHECK_MODE
} from "../../constants/actionTypes";
import { BASE_API_URL } from "../../constants/constants";
import { getUserToken } from "../../services/localStorage";

export const fetchKnowledgeCheck = async (dispatch, module_section_id) => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    try {
        dispatch({ type: FETCH_KNOWLEDGE_CHECK_REQUEST });
        const response = await fetch(`${BASE_API_URL}/knowledge_checks?module_section_id=${module_section_id}`, headers);
        if (!response.ok) throw new Error(response.status);
        const knowledgeCheck = await response.json();
        dispatch({ type: FETCH_KNOWLEDGE_CHECK_SUCCESS, knowledgeCheck: knowledgeCheck || [] });
    } catch (error) {
        dispatch({ type: FETCH_KNOWLEDGE_CHECK_ERROR, errors: error });

    }
};

export const submitKnowledgeCheck = async (dispatch, knowledgeContent) => {
    const token = getUserToken();
    const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
    };
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body:  JSON.stringify({knowledge_check_answers: knowledgeContent}),
      };
    try {
        dispatch({ type: POST_KNOWLEDGE_CHECK_REQUEST });
        const response = await fetch(`${BASE_API_URL}/knowledge_check_answers`, requestOptions);
        if (!response.ok) throw new Error(response.status);
        const knowledge_check_answers = await response.json();
        dispatch({ type: POST_KNOWLEDGE_CHECK_SUCCESS, currentGradeKCA: knowledge_check_answers || [] });
    } catch (error) {
        dispatch({ type: FETCH_KNOWLEDGE_CHECK_ERROR, errors: error });

    }
};

export const setKnowledgeCheckMode = async (dispatch,knowledgeCheckMode) => {
    dispatch({ type: POST_KNOWLEDGE_CHECK_MODE,knowledgeCheckMode });
};