import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import SignInForm from "../components/SignIn";
import SignupForm from "../components/SignUp";
import ResetPassword from "../components/ResetPassword";
import ConfirmPassword from "../components/ConfirmPassword"

import Dashboard from "../pages/Dashboard/Dashboard";
import PageNotFound from "../pages/PageNotFound";
import fetchIntercept from 'fetch-intercept';
const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: async function (response) {
        // Modify the reponse object
        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                await localStorage.removeItem("user");
                window.location.href = "/signin";
            }
        }
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});
const Routes = () => {
    return (
        <Switch>
            <PublicRoute exact path="/" component={SignInForm} />
            <PublicRoute exact path="/signin" component={SignInForm} />
            <PublicRoute exact path="/signup" component={SignupForm} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <PublicRoute path="/reset_password" component={ConfirmPassword} />
            <PrivateRoute exact path="/require-new-password" component={ConfirmPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route component={PageNotFound} />
        </Switch>
    );
};

export default Routes;
