export const LOGIN_WITH_EMAIL_REQUEST = "LOGIN_WITH_EMAIL_REQUEST";
export const LOGIN_WITH_EMAIL_SUCCESS = "LOGIN_WITH_EMAIL_SUCCESS";
export const LOGIN_WITH_EMAIL_ERROR = "LOGIN_WITH_EMAIL_ERROR";

export const SIGNUP_WITH_EMAIL_REQUEST = "SIGNUP_WITH_EMAIL_REQUEST";
export const SIGNUP_WITH_EMAIL_SUCCESS = "SIGNUP_WITH_EMAIL_SUCCESS";
export const SIGNUP_WITH_EMAIL_ERROR = "SIGNUP_WITH_EMAIL_ERROR";

export const LOGIN_WITH_GOOGLE_REQUEST = "LOGIN_WITH_GOOGLE_REQUEST";
export const LOGIN_WITH_GOOGLE_SUCCESS = "LOGIN_WITH_GOOGLE_SUCCESS";
export const LOGIN_WITH_GOOGLE_ERROR = "LOGIN_WITH_GOOGLE_ERROR";

export const RESET_PASSWORD_SUCCESS_EMAIL = "RESET_PASSWORD_SUCCESS_EMAIL"

export const RESET_PASSWORD_UPDATE_REQUEST = "RESET_PASSWORD_UPDATE_REQUEST"
export const RESET_PASSWORD_UPDATE_SUCCESS_EMAIL = "RESET_PASSWORD_UPDATE_SUCCESS_EMAIL"
export const RESET_PASSWORD_UPDATE_ERROR_EMAIL = "RESET_PASSWORD_UPDATE_ERROR_EMAIL"

export const LOGOUT = "LOGOUT";

// Courses and Modules
export const FETCH_COURSES_REQUEST = "FETCH_COURSES_REQUEST";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_ERROR = "FETCH_COURSES_ERROR";

// Global Loading
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";

// Certificates
export const FETCH_CERTIFICATES_REQUEST = "FETCH_CERTIFICATES_REQUEST";
export const FETCH_CERTIFICATES_SUCCESS = "FETCH_CERTIFICATES_SUCCESS";
export const FETCH_CERTIFICATES_ERROR = "FETCH_CERTIFICATES_ERROR";

export const FETCH_MODULES_REQUEST = "FETCH_MODULES_REQUEST";
export const FETCH_MODULES_SUCCESS = "FETCH_MODULES_SUCCESS";
export const FETCH_MODULES_ERROR = "FETCH_MODULES_ERROR";

export const SET_CURRENT_SCROLL = "SET_CURRENT_SCROLL";
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO";
export const SET_CURRENT_MODULE = "SET_CURRENT_MODULE";
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";
export const SET_SECTION_PROGRESS_REQUEST = "SET_SECTION_PROGRESS_REQUEST";
export const SET_SECTION_PROGRESS_SUCCESS = "SET_SECTION_PROGRESS_SUCCESS";
export const SET_SECTION_PROGRESS_ERROR = "SET_SECTION_PROGRESS_ERROR";

// Video Progress
export const SET_VIDEO_PROGRESS_REQUEST = "SET_VIDEO_PROGRESS_REQUEST";
export const SET_VIDEO_PROGRESS_SUCCESS = "SET_VIDEO_PROGRESS_SUCCESS";
export const SET_VIDEO_PROGRESS_ERROR = "SET_VIDEO_PROGRESS_ERROR";

// Videos
export const SET_VIDEO_RATE_REQUEST = "SET_VIDEO_RATE_REQUEST";
export const SET_VIDEO_RATE_SUCCESS = "SET_VIDEO_RATE_SUCCESS";
export const SET_VIDEO_RATE_ERROR = "SET_VIDEO_RATE_ERROR";

// Knowledge check
export const FETCH_KNOWLEDGE_CHECK_REQUEST = "FETCH_KNOWLEDGE_CHECK_REQUEST";
export const FETCH_KNOWLEDGE_CHECK_SUCCESS = "FETCH_KNOWLEDGE_CHECK_SUCCESS";
export const FETCH_KNOWLEDGE_CHECK_ERROR = "FETCH_KNOWLEDGE_CHECK_ERROR";
export const POST_KNOWLEDGE_CHECK_REQUEST = "POST_KNOWLEDGE_CHECK_REQUEST";
export const POST_KNOWLEDGE_CHECK_SUCCESS = "POST_KNOWLEDGE_CHECK_SUCCESS";
export const POST_KNOWLEDGE_CHECK_MODE = "POST_KNOWLEDGE_CHECK_MODE";
