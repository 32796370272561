import Rate from 'rc-rate'
import React from 'react'
import ReactPlayer from 'react-player'
import 'rc-rate/assets/index.css';
const CustomVideo = ({ id, url, title, onProgress,user_rating,description,onChangeRating, playing, onPlay }) => {
    return (
        <>
            <p className="video-title">{title || "Video Title"}</p>
            <ReactPlayer onProgress={onProgress}
                         url={url}
                         width="100%"
                         playing={playing}
                         onPlay={() => onPlay(id)} controls />

            <div className="video-description-container">
                <div className="video-description-header">
                    <Rate
                        defaultValue={user_rating[0]?.rating || 0}
                        className="custom-rating"
                        onChange={(videoRate)=> onChangeRating(videoRate,id)}
                        allowClear={true}
                    />
                </div>
                <div className="video-description">
                    <div className="text">Description</div>
                    <div className="description">{description || "No Title"}</div>
                </div>
            </div>
        </>)
}

export default CustomVideo
