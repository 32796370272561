import React from 'react'
import bounceBack from '../assets/bounceback.svg'
import {Image} from "react-bootstrap";
function Logo() {
    return (
        <Image fluid src={bounceBack} alt="logo" style={{padding:10}} />
    )
}

export default Logo
