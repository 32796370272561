import React, { useEffect, useRef, useState } from 'react'
import CustomVideo from './CustomVideo'
import '../styles/Sections.css'
import KnowledgeCheckBox from '../components/KnowledgeCheckBox'
import KnowledgeCheckList from './KnowledgeCheckList'
import { useDashboardContext } from '../context/dashboardContext'
import { fetchKnowledgeCheck, setKnowledgeCheckMode } from '../context/dashboardContext/knowledgeCheckActions'
import { setVideoProgress } from '../context/dashboardContext/dashboardActions'
import { useAuthContext } from '../context/authContext'
import { setVideoRate } from '../context/dashboardContext/dashboardActions'

const percentages = [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 0.97, 1];
const SectionHome = ({ videos }) => {
    const { knowledgeChecks, currentSection, dispatch, currentModule, currentVideo } = useDashboardContext();
    const { state: { user } } = useAuthContext();
    const [isStart, setStart] = useState(false);
    const [videoProgresses, setVideoProgresses] = useState({});
    const [currentPlayingVideo, setCurrentPlayingVideo] = useState(0);
    const [currentVideoProgress, setCurrentVideoProgress] = useState(null)
    const unmountRef = useRef([]);

    // scroll to view
    useEffect(() => {
        let temp = {};
        videos.forEach(video => {
            let progresses = video.progresses.length && video.progresses[0].progress || 0;
            let videoProgress = percentages
                .filter(percentage => progresses / video.duration <= percentage)
                .map(percentage => percentage * video.duration);

            if (!temp[video.id]) temp[video.id] = [];
            temp[video.id] = videoProgress;
        })

        setVideoProgresses({...temp});

        if (currentVideo) {
            const element = document.getElementById(currentVideo.id);
            if (element) element.scrollIntoView({behavior: 'smooth'});
        }
    }, [currentVideo]);

    useEffect(() => {

        // when component unmount, send the played value to the API
        return () => {
            if (unmountRef.current) {
                unmountRef.current.forEach(e => sendVideoProgress(e));

                unmountRef.current = [];
            }
        }
    }, [currentModule]);

    const sendVideoProgress = (video) => {
        if (video.playedSeconds > video.progresses) {
            const videoDuration = +video.duration;
            const percentToDone = videoDuration > 1200 ? 0.97 : 0.95;
            const almostDone = video.playedSeconds >= videoDuration * percentToDone;
            const module_section_videos_progress_params = {
                "progress": almostDone ? videoDuration : video.playedSeconds,
                "module_section_video_id": video.video,
                "status": "in_progress",
                "check": almostDone,
                "user_id": user.id,
            }

            setVideoProgress(dispatch, module_section_videos_progress_params, currentModule);
        }
    }

    const onClick = () => {
        setStart(prevIsStart => !prevIsStart);
        fetchKnowledgeCheck(dispatch, currentSection?.id);
        setKnowledgeCheckMode(dispatch, true)
    }
    const onProgress = (progress, video) => {
        if (progress) {
            setCurrentVideoProgress(progress);
            let index = unmountRef.current.findIndex(e => e.video === video.id);

            let objectVideo = {
                video: video.id,
                duration: video.duration,
                progresses: (video.progresses.length && video.progresses[0].progress) || 0,
                playedSeconds: progress.playedSeconds
            };

            if (index === -1) unmountRef.current.push(objectVideo);
            else unmountRef.current[index].playedSeconds = progress.playedSeconds;

            if (videoProgresses[video.id].some(percentage => percentage < progress.playedSeconds)) {
                let temp = videoProgresses[video.id].filter(percentage => percentage < progress.playedSeconds);
                temp = videoProgresses[video.id].filter(percentage => !temp.some(e => e === percentage));

                sendVideoProgress(objectVideo);

                videoProgresses[video.id] = [...temp];
                setVideoProgresses({...videoProgresses});
            }
        }
    }

    const goBack = () => {
        setKnowledgeCheckMode(dispatch, false)
        setStart(prevIsStart => !prevIsStart);
    }
    const onChangeRating = (videoRate, videoId) => {
        setVideoRate(dispatch,videoId,videoRate,currentModule);
    }

    return (
        <>
            {!isStart && <div className="section-home-container">
                {videos && videos.length > 0 && React.Children.toArray(videos.map((video, index) => {
                    return <div id={`video-${video.id}`} key={index}>
                        <CustomVideo {...video}
                                     playing={currentPlayingVideo === 0 ? false : currentPlayingVideo === video.id}
                                     onPlay={(videoId) => setCurrentPlayingVideo(videoId)}
                                     onChangeRating={onChangeRating}
                                     onProgress={(progress) => onProgress(progress, video)} />
                    </div>
                }))}
                {!isStart && currentSection && currentSection.has_pending_checks && currentVideoProgress && <KnowledgeCheckBox onClick={onClick} />}
            </div>}
            {isStart && <KnowledgeCheckList dispatch={dispatch} content={knowledgeChecks} goBack={goBack} />}
        </>
    )
}



export default SectionHome
