import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./navigation/Routes";
import { AuthProvider } from "./context/authContext";
function App() {
  return (
    <AuthProvider>
        <Router>
          <div className="App">
            <Routes />
          </div>
        </Router>
    </AuthProvider>
  );
}

export default App;
