import { useCallback, useEffect, useRef, useState } from 'react'

const useOutsideClick = (isVisibleDefault) => {
    const [isElementVisible, setIsElementVisible,] = useState(isVisibleDefault);
    const ref = useRef(null);

    const handleClickOutside = useCallback(
        event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsElementVisible(false);
            }
        },
        [setIsElementVisible]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handleClickOutside]);
    return {
        ref,
        isElementVisible,
        setIsElementVisible
    }
}

export default useOutsideClick
