import React, {useEffect, useState} from 'react'
import {logout, useAuthContext} from '../context/authContext';
import {useHistory} from 'react-router-dom';
import Menu, {MenuItem} from './Menu';
import {useDashboardContext} from '../context/dashboardContext';
import UserIconSVG from '../assets/icons/user.svg';
import '../styles/User.css';
import {courseStatuses} from '../constants/constants';
import useOutsideClick from '../hooks/useOutsideClick';
import CustomButton from "./CustomButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {downloadCertificates, getUserCertificates} from "../context/dashboardContext/certificatesActions";
import CustomSpinner from "./CustomSpinner";
import {Modal} from "react-bootstrap";
import {setGlobalLoading} from "../context/dashboardContext/dashboardActions";

const UserSettings = (props) => {
    const {state: {user}, dispatch} = useAuthContext();
    const {courses, certificates, dispatch: dashDispatch, globalLoading} = useDashboardContext();
    const inProgressCourses = courses.length > 0 && courses.filter(course => course.progresses.length && course.progresses[0].status === courseStatuses.IN_PROGRESS);
    const {ref, isElementVisible, setIsElementVisible} = useOutsideClick(false);
    const [showCertificates, setShowCertificates] = useState(false);

    const history = useHistory();
    const handleLogOut = async () => {
        history.push('/signin');
        await logout(dispatch);
    }

    const userMenuItems = {

        items: [
            {
                name: "My courses",
                items: courses
            },

            inProgressCourses.length > 0 && {
                name: "In Progress",
                items: inProgressCourses
            },
        ].filter(Boolean),

    }

    const fetchUserCertificates = () => {
        if (!showCertificates) {
            setShowCertificates(true);
            return getUserCertificates(dashDispatch);
        }
        setShowCertificates(false);
    }

    const downloadCertificate = (user_certificate) => {
        setGlobalLoading(dashDispatch, true);
        downloadCertificates(user_certificate.course.id).then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "certificate.pdf";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setGlobalLoading(dashDispatch, false);
            }).catch((err) => {
            setGlobalLoading(dashDispatch, true);
        });
    }

    const UserMenu = () => {
        return (
            <>
                {
                    Object.keys(userMenuItems).map((key, i) => (
                        <Menu isFlatMenu={true} key={`menu-item-${key}-${i * 10}`} items={userMenuItems[key]}/>
                    ))
                }
                <UserCertificates/>
            </>
        );
    }

    const UserCertificates = () => {
        return (
            <>
                <ul className={`menu isFlatMenu user-select-none`}>
                    <li onClick={() => {
                        fetchUserCertificates();
                    }}
                        className={`menu-item ${showCertificates ? 'expanded' : ''} hasSubmenu isFlatMenu`}>
                        <div className="menu-item-title">My certificates</div>
                    </li>

                    <li>
                        {certificates.loading && <div className={`my-2`}><CustomSpinner/></div>}
                    </li>

                    <ul className={`menu ${showCertificates ? 'd-block' : 'd-none'}`}>
                        {
                            certificates.data.map((item, key) => {
                                return (
                                    <li key={key} className="menu-item isFlatMenu user-certificate-setting-card">
                                        <div className="menu-item-title d-flex justify-content-between">
                                            <span>{item?.course.name}</span>
                                            <span className={`ml-2`}><FontAwesomeIcon className={`text-success`}
                                                                                      icon={faCheckCircle}/></span>
                                        </div>
                                        <button onClick={() => {
                                            downloadCertificate(item)
                                        }} className="custom-button-default btn-view-certificate w-100 my-2">Get
                                            Certificate
                                        </button>
                                    </li>
                                )
                            })
                        }

                        {!certificates.loading && !certificates.data.length && <><div className="alert alert-info text-center">No data</div></>}
                    </ul>
                </ul>
            </>
        )
    }

    const handleManageAccount = () => {
        // TODO implement logic
    }

    return (
        <>
            <div className={props.className} ref={ref}>
                <img src={UserIconSVG} alt="user" width={40} height={40} onClick={() => setIsElementVisible(true)}/>
                {isElementVisible && (
                    <div className="user-setting-menu-container">
                        <div className="user-menu-header">
                            <div className="user-name">{user?.username || user?.first_name}</div>
                            <div className="user-email">{user?.email}</div>
                        </div>
                        <button className="custom-button-blue" onClick={handleManageAccount}>Manage your account
                        </button>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <div className={`user-menu-content`}>
                                <UserMenu/>
                            </div>

                            <div className={`text-center mt-4`}>
                                <CustomButton className="custom-button-white w-50" label="Sign out"
                                              onClick={handleLogOut}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default UserSettings
