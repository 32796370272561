import React from 'react'
import '../styles/KnowledgeCheck.css';
import '../App.css';
import CertificationIcon from '../assets/icons/certification.svg'

const KnowledgeCheckBox = ({ prefix, onClick }) => {
    const subtitle = prefix ? `${prefix} knowledge check` : `knowledge check`;
    return (
        <div className="knowledge-check-container">
            <div className="left-container  mb-3 mb-md-0 ">
                <div className="certification-container">
                    <img src={CertificationIcon} alt="certification" width={32} height={32} />
                </div>
                <div className="title">Knowledge Check</div>
            </div>
            <button className="custom-button-danger" onClick={onClick}>Start</button>
        </div>
    )
}



export default KnowledgeCheckBox
