import {
    FETCH_COURSES_REQUEST,
    FETCH_COURSES_SUCCESS, FETCH_MODULES_ERROR,
} from "../../constants/actionTypes";
import { BASE_API_URL } from "../../constants/constants";
import { getUserToken } from "../../services/localStorage";
import { retrieveModules } from "./dashboardActions";
export const fetchCourses = async (dispatch) => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    try {
        dispatch({ type: FETCH_COURSES_REQUEST });
        const response = await fetch(`${BASE_API_URL}/courses`, headers);
        if (!response.ok) throw new Error(response.status);
        const courses = await response.json();
        const modules = await retrieveModules();
        const courseModules = courses.map(course => {
            const mods = modules.filter(module => module.course.id === course.id);
            return {
                id:course.id,
                name: course.name,
                items: mods
            }
        }) || [];
       
        dispatch({ type: FETCH_COURSES_SUCCESS, modules: modules || [], courses: courses || [], courseModules});
    } catch (error) {
        dispatch({ type: FETCH_MODULES_ERROR, errors: error });

    }
};