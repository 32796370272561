import React, {useContext, useEffect, useState} from 'react'
import {AccordionContext, Card, useAccordionToggle} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import '../styles/Course.css';
import CheckedMarkIcon from '../assets/icons/checked-mark.svg';
import {ProgressBar} from 'react-bootstrap';
import {useDashboardContext} from '../context/dashboardContext'

const SectionItem = ({
                         name,
                         children,
                         eventKey,
                         defaultActiveKey,
                         sectionScore = 50,
                         onSetCurrentSection,
                         index,
                         section,
                         activeKey
                     }) => {
    const {knowledgeCheckMode, currentSection, currentModule, modules} = useDashboardContext();
    const sectionName = name ? name : "Section Name";
    const [sectionTotal, setSectionTotal] = useState(1);

    let isCurrentActiveKey = false;
    const ContextAwareToggle = ({_, eventKey, callback}) => {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const _onClick = () => {
            decoratedOnClick();
            onSetCurrentSection(section);
        }
        isCurrentActiveKey = currentEventKey === eventKey;

        const getTotal = (currentSection = section) => {
            return currentSection && currentSection.items.reduce((a, b) => {
                return a + (+b.duration);
            }, 0);
        }

        const getTotalProgress = (currentSection = section) => {
            return currentSection && currentSection.items.map(e => e.progresses[0]).reduce((a, b) => {
                return (+a) + (b && +b.progress || 0);
            }, 0);
        }

        useEffect(() => {
            let curModule = modules.find(e => e.id === currentModule.id);
            let curSection = curModule && curModule.items && curModule.items.find(e => e.id === section.id);

            const total = (getTotalProgress(curSection ?? section) / getTotal(curSection ?? section)) * 100
            setSectionTotal(!isNaN(total) ? (total > 100) ? 100 : Math.ceil(total) : 0);
        }, [currentSection, currentModule]);

        return (
            <>
                {!knowledgeCheckMode && <div className="section-header-container" id={`sec-${section.id}`} onClick={_onClick}>
                    <div className="section-header">
                        <img src={CheckedMarkIcon} alt='checked mark'/>
                        <div className="section-name">{sectionName}</div>
                    </div>
                    <div className="section-progress-container">
                        <ProgressBar className="section-progress" now={sectionTotal}/>
                        <div className={`section-score ${isCurrentActiveKey ? 'active' : ''}`}>Progress <span
                            className="score">{sectionTotal.toFixed(0)}%</span></div>
                    </div>
                </div>}
            </>
        );
    }

    return (
        <Card>
            <ContextAwareToggle eventKey={eventKey.toString()}/>
            <Accordion.Collapse className={`section-item-body`} eventKey={eventKey.toString()}>
                <Card.Body>{children && children}</Card.Body>
            </Accordion.Collapse>
        </Card>

    )
}


export default SectionItem;
