import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';

const PublicRoute = (props) => {
    const location = useLocation();

    const { state: { user } } = useAuthContext();

    // if user exists and not require new password
    if (user && !user.require_new_password)
        return <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />

    // if user exists and new password is required
    if (user && user.require_new_password && location.pathname !== '/signin')
        return <Redirect to={{ pathname: '/require-new-password', state: { from: location } }} />

    return <Route {...props} />
}

export default PublicRoute
