import React from 'react'
import clsx from "clsx";
import { useDashboardContext } from '../context/dashboardContext';
import {
    SET_CURRENT_MODULE,
    SET_CURRENT_SECTION,
    POST_KNOWLEDGE_CHECK_MODE,
    SET_CURRENT_VIDEO
} from '../constants/actionTypes';

export const MenuItem = ({ item, isFlatMenu }) => {
    const [expanded, setExpanded] = React.useState();
    const [isModuleOpen, setIsModuleOpen] = React.useState(false);
    const [isSectionOpen, setIsSectionOpen] = React.useState(false);
    const { dispatch, modules, currentModule } = useDashboardContext()
    let hasSubmenu = item.items && item.items.length;

    const toggle = (e, module) => {
        e.stopPropagation();
        setExpanded(prevExpanded => !prevExpanded);
        if (!isFlatMenu) {
            setCurrentModule(module);
        }
    }
    const toggleVideo = (e, video) => {
        e.stopPropagation();
        e.preventDefault();
        if(video.module_section_id){
            dispatch({ type: SET_CURRENT_VIDEO, module: module, video: video });
        }
    }
    const setCurrentModule = (module) => {
        // TODO to define with BE a flag to see when is a module, section or item
        //Section
        setIsModuleOpen(false);
        setIsSectionOpen(false);
        if (module?.course_module_id) {//current section
            setIsSectionOpen(prev=>!prev);

            if(currentModule){
                // Check if current section belongs to current module
                const isSectionInCurrentModule = module.course_module_id === currentModule.id;

                // If not, find and set the right module
                if(!isSectionInCurrentModule){
                    let selectedModule = modules.find(m => m.id === module.course_module_id)
                    dispatch({ type: SET_CURRENT_MODULE, module: selectedModule });
                }
            }

            dispatch({ type: SET_CURRENT_SECTION, section: module });
            // Dispatch click over section in main container
            console.clear();
            try{
                const section = document.getElementById(`sec-${module.id}`);
                const isNotCollapsed = section.nextElementSibling.classList.contains('show');
                if(!isNotCollapsed){
                    section.click();
                }
            }catch {
            }

        } else if (module?.progresses && !expanded) { //current Module
            dispatch({ type: SET_CURRENT_MODULE, module });
            setIsModuleOpen(prev=>!prev);
            dispatch({ type: POST_KNOWLEDGE_CHECK_MODE, knowledgeCheckMode: false })
        } else {
            dispatch({ type: SET_CURRENT_MODULE, module: null });
        }

    }

    let renderChild = null;
    if (item.items && item.items.length && expanded) {
        renderChild = (
            <ul className={clsx("menu", { isFlatMenu, isModuleOpen, isSectionOpen })}>
                {item.items.map((item, i) => (
                    <MenuItem isFlatMenu={isFlatMenu} item={item} key={`${item.name || item.title}-item-${i * 10}`} />)
                )}
            </ul>
        )
    }
    const nameLink = item.name || item.title;
    return (
        <>
            <li className={clsx("menu-item", { expanded, hasSubmenu, isFlatMenu, isModuleOpen,isSectionOpen })}>
                <div className="menu-item-title" onClick={
                    hasSubmenu
                        ? (e) => toggle(e, item)
                        : (e) => toggleVideo(e, item)}>
                    {nameLink}
                </div>
            </li>
            {renderChild}
        </>

    )
}

const Menu = ({ items, isFlatMenu }) => {
    const sortItem = (a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
    }

    return !items?.length ? null : (
        <ul className={clsx("menu", { isFlatMenu })}>
            {items.sort(sortItem).map(item => {
                return (
                    <MenuItem key={item.name} item={item} isFlatMenu={isFlatMenu} />)
            })}
        </ul>
    );
};

export default Menu;
