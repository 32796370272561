
import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useDashboardContext } from "../context/dashboardContext";
import CustomSpinner from "./CustomSpinner";
import { submitKnowledgeCheck } from '../context/dashboardContext/knowledgeCheckActions'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import CustomButton from './CustomButton';
import CustomModalKnowledgeCheck from "./CustomModalKnowledgeCheck";

import '../App.css';
import { useAuthContext } from "../context/authContext";

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
const RadioList = ({ text, options, index, multiple, answers, setAnswers }) => {

    const onSave = (answers, item) => {
        const resUpdate = answers.find(element => element.knowledge_check_question_id === item.knowledge_check_question_id)
        if (resUpdate) {
            const updateAnswers = answers.map((ans) => {
                if (ans.knowledge_check_question_id === item.knowledge_check_question_id) {
                    ans = item
                }
                return ans
            })
            setAnswers([...updateAnswers])
        } else {
            setAnswers([...answers, item])
        }
    }

    const onSaveCheckBox = (answers, item) => {
        const resUpdate = answers.find(element => element.id === item.id)
        if (resUpdate) {
            const updateAnswers = answers.filter((element => element !== item))
            setAnswers([...updateAnswers])

        } else {
            setAnswers([...answers, item])

        }
    }

    const isChecked = (item) => {
        const result = answers.find((element => element.id === item.id))
        if (result) {
            return true
        }
        return false
    }

    return (
        <Container>
            <Row>
                <Col sm className="custom-label-question-number">Question {index + 1}</Col>
            </Row>
            <Row>
                <Col sm className="custom-label-question">{text}</Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup variant="flush">
                        {options.map((item, idx) =>
                            <div className="" key={`${item.name}-${idx * 10}`}>
                                <div className="custom-question-container ">
                                    <div className="radio" onClick={() => setAnswers(answers => multiple ? onSaveCheckBox(answers, item) : onSave(answers, item))}>
                                        <div className="custom-label-button-radio">
                                            <label className="custom-label-text">{alphabet[idx]}</label>
                                            <input
                                                id={`inline-check-${item.name}`}

                                                name={`${item.knowledge_check_question_id}`}
                                                type={multiple ? "checkbox" : "radio"}
                                                value={item.name}
                                                checked={isChecked(item)}
                                            />
                                        </div>
                                    </div>
                                    <label id={`inline-check-${item.name}`} className="custom-label-description">{item.name}</label>

                                </div>
                            </div>
                        )
                        }
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
}

const KnowledgeCheckList = ({ content, dispatch, goBack }) => {
    const { state: { user } } = useAuthContext();
    const { name = "", knowledge_check_questions_options_answers = [] } = content[0] || [];
    const [answers, setAnswers] = useState([]);
    const [modal, setModal] = useState(false);
    const { totalCorrect, currentGradeKCA } = useDashboardContext();
    const lineDiv = { border: "1px solid #E5E7EB" }

    const toggle = () => setModal(!modal);

    const onSubmit = () => {
        const answersMap = answers.map((item) => {
            return {
                "name": item?.name,
                "knowledge_check_question_id": item?.knowledge_check_question_id,
                "knowledge_check_option_id": item?.id,
                "user_id": user.id
            }
        })
        submitKnowledgeCheck(dispatch, answersMap)
    }

    useEffect(() => {
        if (currentGradeKCA.length > 0) {
            toggle()
        }
    }, [currentGradeKCA])
    const { isFetchingKnowledgeChecks } = useDashboardContext();
    if (isFetchingKnowledgeChecks) return <CustomSpinner />;
    return knowledge_check_questions_options_answers.length > 0 ? (
        <Container className="knowledge-check-list-container">
            <CustomButton className="custom-button-back" label="Back" onClick={goBack} icon={faAngleLeft} />
            <Row className={`mt-3`}>
                <Col lg={12}>
                    <h2>{name}</h2>
                </Col>
            </Row>
            <div style={lineDiv}></div>
            {knowledge_check_questions_options_answers.map((item, index) =>
                <Row className="custom-question-answers" key={`${item.name}-${item.knowledge_check_question_id + index * 5}`}>
                    <Col lg={12}>
                        <br></br>
                        <RadioList
                            text={item.name}
                            options={item.knowledge_check_options}
                            index={index}
                            multiple={item.multiple}
                            setAnswers={setAnswers}
                            answers={answers}
                        />
                    </Col>

                </Row>)}
            <br></br>
            <div className="custom-submit-container">
                <button className="custom-submit-button" onClick={onSubmit}>
                    <label className="custom-submit-button-text">Save</label>
                </button>
            </div>
            <CustomModalKnowledgeCheck
                isOpen={modal}
                handleClose={toggle}
                showFooter={true}
                fullscreen={true}
                score={Number(totalCorrect).toFixed(1)}
            />
        </Container>
    ) : <div>No data</div>
}

export default KnowledgeCheckList
