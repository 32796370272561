import {
    FETCH_MODULES_REQUEST,
    FETCH_MODULES_SUCCESS,
    FETCH_MODULES_ERROR,
    SET_SECTION_PROGRESS_REQUEST,
    SET_SECTION_PROGRESS_SUCCESS,
    SET_CURRENT_SECTION,
    SET_VIDEO_RATE_ERROR,
    SET_VIDEO_RATE_SUCCESS,
    SET_VIDEO_PROGRESS_REQUEST, SET_VIDEO_PROGRESS_SUCCESS, SET_GLOBAL_LOADING
} from "../../constants/actionTypes";
import { BASE_API_URL } from "../../constants/constants";
import { getUserToken } from "../../services/localStorage";
export const fetchModules = async (dispatch) => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    try {
        dispatch({ type: FETCH_MODULES_REQUEST });
        const response = await fetch(`${BASE_API_URL}/course_modules`, headers);
        if (!response.ok) throw new Error(response.status);
        const modules = await response.json();

        dispatch({ type: FETCH_MODULES_SUCCESS, modules: modules || [] });
    } catch (error) {
        console.log("Something went wrong on fetchModules:", error);
        dispatch({ type: FETCH_MODULES_ERROR, errors: error });

    }
};
export const setSectionProgress = async (dispatch, module_section_progress,currentModule) => {
    const token = getUserToken();

    const headers = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ module_section_progress }),
    };

    try {
        dispatch({ type: SET_SECTION_PROGRESS_REQUEST });

        const response = await fetch(`${BASE_API_URL}/module_section_progresses`, headers);
        if (!response.ok) throw new Error(response.status);
        const progressResponse = await response.json();
        const newSection = {
            id: module_section_progress.module_section_id,
            progress:progressResponse.progress
        }
        dispatch({ type: SET_SECTION_PROGRESS_SUCCESS, section:newSection,module:currentModule });
    } catch (error) {
        console.log("Something went wrong on fetchModules:", error);
        dispatch({ type: SET_CURRENT_SECTION, errors: error });

    }
};

export const setVideoProgress = async (dispatch, module_section_videos_progress_params, currentModule) => {
    const token = getUserToken();

    const headers = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ module_section_videos_progress_params }),
    };

    try {
        dispatch({ type: SET_VIDEO_PROGRESS_REQUEST });

        const response = await fetch(`${BASE_API_URL}/module_section_videos_progresses`, headers);
        if (!response.ok) throw new Error(response.status);
        const progressResponse = await response.json();

        dispatch({ type: SET_VIDEO_PROGRESS_SUCCESS, currentVideo: progressResponse, currentModule: currentModule });
    } catch (error) {
        console.log("Something went wrong on fetchModules:", error);
        dispatch({ type: SET_CURRENT_SECTION, errors: error });

    }
}

export const setVideoRate = async (dispatch, videoId, rating,currentModule) => {
    const token = getUserToken();
    const module_section_video = {
       rating
    }
    const headers = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ module_section_video }),
    };

    try {
        dispatch({ type: SET_SECTION_PROGRESS_REQUEST });

        const response = await fetch(`${BASE_API_URL}/module_section_videos/${videoId}/rate`, headers);
        if (!response.ok) throw new Error(response.status);
        const rateResponse = await response.json();
        dispatch({ type: SET_VIDEO_RATE_SUCCESS, currentModule, currentVideo:rateResponse });
        return rateResponse;
    } catch (error) {
        console.log("Something went wrong on setVideoRate:", error);
        dispatch({ type: SET_VIDEO_RATE_ERROR, errors: error });

    }
};

export const retrieveModules = async () => {
    const token = getUserToken();
    const headers = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    try {

        const response = await fetch(`${BASE_API_URL}/course_modules`, headers);
        if (!response.ok) throw new Error(response.status);
        return await response.json();

    } catch (error) {
        console.log("Something went wrong on retrieveModules:", error);

    }
}

export const setGlobalLoading = async (dispatch, state) => {
    dispatch({ type: SET_GLOBAL_LOADING, loading: state})
}
