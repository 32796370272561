import React, {useEffect} from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import { DashboardProvider } from '../context/dashboardContext';

const PrivateRoute = (props) => {
    const location = useLocation();
    const { state: { user } } = useAuthContext();

    if (location.pathname !== '/require-new-password' && user && user.require_new_password)
        return <Redirect to={{ pathname: '/require-new-password', state: { from: location } }} />

    return user ? (

        <DashboardProvider>
            <Route {...props} />
        </DashboardProvider>

    ) : (
        <Redirect to={{ pathname: '/signin', state: { from: location } }} />
    )
}

export default PrivateRoute
