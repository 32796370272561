import React, { useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import CustomButton from "./CustomButton";
import appStyle from "../styles/App.module.css";
import { Link, useHistory } from "react-router-dom";
import { loginWithGoogle, signupWithEmail, resetEmail } from "../context/authContext/authActions";
import { useAuthContext } from "../context/authContext";
import Logo from "./Logo";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import AlertDismissible from "./AlertDismissible";
const SignUpForm = () => {
  const { dispatch, state: { error } } = useAuthContext();
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [inputs, setInputs] = useState({ email_address: ''});
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [showAlert, setShowAlert] = useState(false)


  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
  }
  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    const { email_address } = inputs;
    const payload = { user: { email: email_address} };
    const data = await resetEmail(dispatch, payload);
    setShowAlert(true);

  }
  const onCloseAlert = () => {
    setShowAlert(prevShowAlert => !prevShowAlert);
  }
  const Errors = () => {
    return (<AlertDismissible variant={"success"} content={'Reactivation link was sent to your email inbox'} showAlert={showAlert} onCloseAlert={onCloseAlert} />)

  }
  return (
    <>
      <div className={appStyle["form-wrapper"]}>
        <div className={appStyle["form-header-container"]}>
          <Logo />
          <div className={`pb-0 pt-3 ` + appStyle['form-header-title']}>Password reset</div>
          <p className={appStyle.text}>Enter the Email Address you used when you joined and we’ll send you instructions to reset your password.</p>

        </div>
        {<Errors />}

        <Form onSubmit={handleSignupWithEmail}>
          <div className={appStyle['b-form-body']}>
          <Form.Group className={appStyle['custom-form-group']}>
          <div className={appStyle['form-custom-label']}>Email Address</div>
            <Form.Control type="text" placeholder="Email Address" value={inputs["email_address"]} onChange={onChangeHandler} required name="email_address" pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"} />
          </Form.Group>
          </div>
          <Row className={`justify-content-between`}>
            <Col xs lg="3">
              <CustomButton className="custom-button-back btn-back d-flex" label="Back" onClick={() => history.goBack()} icon={faAngleLeft} />
            </Col>
            <Col lg={`4`}>
              <CustomButton
              label="Send"
              className={`custom-button-default ${!inputs.email_address ? 'disabled' :'' }`}
              type="submit"
            />
            </Col>
          </Row>
          <div className={appStyle["form-footer"]}>
            <p>
              Don’t have an account?{" "}
              <span>
              <Link to="/signup" className={appStyle['custom-link-blue']} >Sign up</Link>
              </span>
            </p>
          </div>

        </Form>
      </div>
    </>
  );
};

export default SignUpForm;
