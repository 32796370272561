import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {useLocation} from 'react-router';
import appStyle from "../styles/App.module.css";
import {Link, useHistory} from "react-router-dom";
import {MAIN_DOMAIN} from "../constants/constants";
import {loginWithGoogle, signupWithEmail, resetEmail, updatePassword} from "../context/authContext/authActions";
import {useAuthContext} from "../context/authContext";
import useEventListener from "../hooks/useEventListener";
import AlertDismissible from "./AlertDismissible";
import CustomButton from "./CustomButton";
import Logo from "./Logo";
import {LOGOUT} from "../constants/actionTypes";

const SignUpForm = (props) => {
    const {dispatch, state: {user, error}} = useAuthContext();
    const history = useHistory();
    const location = useLocation();
    const token = useQuery();
    const [modal, setModal] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);
    const [invalidFormMsg, setInvalidFormMsg] = useState('');
    const [inputs, setInputs] = useState({
        email_address: user ? user.email : '',
        password: '',
        confirm_password: ''
    });
    const [isTCAccepted, setIsTCAccepted] = useState(false);
    const [showAlert, setShowAlert] = useState(false)
    const newWindowRef = useRef(null);

    const onMessageReceiveFromBackend = React.useCallback(async (event) => {
        if (event.origin.startsWith(MAIN_DOMAIN)) {
            if (event.data.user) {
                await loginWithGoogle(dispatch, event.data.user);
                if (newWindowRef.current) {
                    newWindowRef.current.window.close();
                }

            }

        } else {
            console.log('Unauthorized event!', event);
        }

    }, [dispatch]);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    useEventListener(onMessageReceiveFromBackend);

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setInputs(prevState => ({...prevState, [name]: value}));
    }
    const handleSignupWithEmail = async (e) => {
        e.preventDefault();
        setInvalidForm(false)
        const {email_address, password, confirm_password} = inputs;

        if(user.email !== email_address){
            setInvalidForm(true)
            setInvalidFormMsg('Use a valid email address')
            return
        }

        const payload = {
            user: {
                email: email_address,
                password: password,
                require_new_password: user.require_new_password,
                password_confirmation: confirm_password,
                reset_password_token: token.toString()?.replace("reset_password_token=", "")
            }
        };
        const data = await updatePassword(dispatch, payload);

        if (user.require_new_password) {
            await localStorage.removeItem("user");
            dispatch({type: LOGOUT});
        }

        history.push('/signin');
    }
    const onCloseAlert = () => {
        setShowAlert(prevShowAlert => !prevShowAlert);
    }
    const Errors = () => {
        let output = "";
        if (typeof error === "object") {
            for (const key in error) {
                if (Object.hasOwnProperty.call(error, key)) {
                    const sError = error[key];
                    output += `${key}:${sError}\n`;
                }
            }
        } else {
            output = error || 'Unexpected error';
        }
        return (
            <AlertDismissible variant={"danger"} content={output} showAlert={showAlert} onCloseAlert={onCloseAlert}/>)

    }

    return (
        <>
            <div className={appStyle["form-wrapper"]}>
                <div className={appStyle["form-header-container"]}>
                    <Logo/>
                    <div className={appStyle['form-header-title']}>Password reset</div>
                    <p className={appStyle.text}>Enter the Email Address you used when you joined and also the
                        new password.</p>
                </div>
                {error && <Errors/>}
                {invalidForm && <AlertDismissible variant={"danger"} content={invalidFormMsg} showAlert={invalidForm} onCloseAlert={()=>{setInvalidForm(false); setInvalidFormMsg('')}}/>}
                <Form onSubmit={handleSignupWithEmail}>
                    <div className={appStyle['b-form-body']}>
                        <Form.Group className={`${user ? 'bg-gray' : 'bg-red'} ${appStyle['custom-form-group']}`}>
                            <div className={appStyle['form-custom-label']}>Email Address</div>
                            <Form.Control className={`user-select-none`} disabled={user ? 'true' : 'false'} type="text" placeholder="Email Address" value={inputs["email_address"]}
                                          onChange={onChangeHandler} required name="email_address"
                                          pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"}/>
                        </Form.Group>
                        <Form.Group className={appStyle['custom-form-group']}>
                            <div className={appStyle['form-custom-label']}>Password</div>
                            <Form.Control type="password" placeholder="Password " value={inputs["password"]}
                                          onChange={onChangeHandler} required name="password"/>
                        </Form.Group>
                        <Form.Group className={appStyle['custom-form-group']}>
                            <div className={appStyle['form-custom-label']}>Confirm Password</div>
                            <Form.Control type="password" placeholder="Confirm Password"
                                          value={inputs["confirm_password"]} onChange={onChangeHandler} required
                                          name="confirm_password"/>
                        </Form.Group>
                    </div>
                    <CustomButton
                        className={`custom-button-default ${!inputs.email_address || !inputs.password || !inputs.confirm_password || inputs.confirm_password !== inputs.password ? 'disabled' : ''}`}
                        type="submit"
                        label="Update Password"
                    >
                    </CustomButton>

                </Form>
                <div className={appStyle["form-footer"]}>
                    <p>
                        Don’t have an account?{" "}
                        <span>
                <Link to="/signin">Sign In</Link>
              </span>
                    </p>
                </div>
            </div>

        </>
    );
};

export default SignUpForm;
