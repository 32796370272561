import React, {useEffect, useState} from "react";
import {Col, Modal, Row} from "react-bootstrap";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {useAuthContext} from "../../context/authContext";
import {useDashboardContext} from "../../context/dashboardContext";
import Module from "../../components/Module";
import styles from '../../styles/Dashboard.module.css';
import Logo from "../../components/Logo";

const Dashboard = () => {
    const {
        state: {user},
    } = useAuthContext();
    const {modules, currentModule, currentSection, globalLoading} = useDashboardContext();
    const defaultModule = currentModule ? currentModule : null;

    const [defaultSection, setDefaultSection] = useState();
    const [newDefaultModule, setNewDefaultModule] = useState();

    useEffect(() => {
        let index = modules.findIndex(e => e.id === currentModule?.id);
        setNewDefaultModule(modules[index]);
        setDefaultSection(currentSection ? currentSection : newDefaultModule && newDefaultModule.length > 0 ? newDefaultModule[0] : '');
    }, [modules]);

    return (
        <div className={styles['dashboard-container']}>
            <Row>
                <Col md={3} xs={12}>
                    <div className="d-none d-md-block logo mt-3 mt-sm-0">
                        <Logo/>
                    </div>
                    <Sidebar/>
                    <div className="d-block d-md-none logo mt-3 mt-sm-0">
                        <Logo/>
                    </div>
                </Col>
                <Col md={9} xs={12} className={styles['dashboard-main-content']}>
                    <Header user={user}/>
                    {defaultModule && <Module module={defaultModule} currentSection={defaultSection}/>}
                </Col>
            </Row>

            {/*Spinner or Blocking Modal*/}
            {
                globalLoading &&
                <div className="loading">
                    <div className="loader"></div>
                </div>
            }
        </div>
    );
};

export default Dashboard;
