import React from 'react'
import '../styles/Module.css';
import GraduationICon from '../assets/icons/graduation.svg'
import CustomButton from './CustomButton';
const ModuleHeader = ({ moduleName, description, onClick }) => {
    return (
        <div className="module-header-container">
            <div className="image-wrapper mb-3 mb-md-0 ">
                <img src={GraduationICon} alt="Graduation" />
            </div>
            <div className="module-titles-container justify-content-sm-center mb-3 mb-md-0  ">
                <div className="title">{moduleName}</div>
                <div className="description">{description}</div>
            </div>
            <div className="module-header-btn ml-auto p-sm-1">
                <CustomButton label="Continue" onClick={onClick }/>
            </div>
        </div>
    )
}



export default ModuleHeader
