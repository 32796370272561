import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../App.css'

const CustomButton = ({ className = "custom-button-default", label, onClick, icon, customIcon,disabled, type }) => {
    return (
        <button className={className} onClick={onClick} disabled={disabled} type={type}>
            {customIcon && <img src={customIcon} alt={label}/>}
            {icon && <FontAwesomeIcon icon={icon} size="lg" />}
            <span>{label}</span>
        </button>
    )
}

export default CustomButton
